import React from 'react';
import Style from '../Components/Style/Style';

const Section = (props) => {
  return (
        <div className={`section ${props.size?props.size.length>0?`section-${props.size}`:'':''} bg-${props.color}`} id={props.id}>
            <div className="container">
                {props.children}
            </div>
            <Style color="var(--white-bg)" />
        </div>
  );
}

export default Section;