import React from 'react';

import './about.min.css';
import Section from '../../Components/Style/Style';

import Section1 from './Sections/Section1';
import SectionFeatures from './Sections/Section_Features';

const About = (props) => {
  
  props.s2t();
  return (
      <div>
        <div className="about section overlay-6" id="home">
          <div className="container grid-xl z-index">
            <h1 className={`${props.font ? "disl-font ":""} mb-0`}>About</h1>
            <h4 className={props.font ? "disl-font ":""}>Heff's Fabrications</h4>
          </div>
          <Section color="var(--color-bg-grey)"/>
        </div>
        <SectionFeatures font={props.font}/>
        <Section1 onSetImage={props.onSetImage} onSetImageCap={props.onSetImageCap} CallFancy={props.CallFancy} font={props.font}/>
      </div>
  );
}

export default About;