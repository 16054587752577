import React from 'react';

const Notifications = (props) => {
    return (
        <div className={`alert-box secondary-alert ${props.show?'show':'hide'}`}>
            <div className="alert">
                <h4 className="alert-heading">{props.title}</h4>
                <p className="text-medium">
                    {props.message}
                </p>
            </div>
        </div>
    );
}

export default Notifications;