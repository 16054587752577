import {
    NAVBAR, BACK_TO_TOP,
    NAME, EMAIL, NUMBER, STREET, DELIVERY, STATE, EMAILED, DESC, NOTIFY } from './constants';
 
export const setNotify = (link) => ({
    type: NOTIFY,
    payload: link
});

export const setEmailed = (link) => ({
    type: EMAILED,
    payload: link
});

export const setNavbar = (link) => ({
    type: NAVBAR,
    payload: link
});

export const setName = (link) => ({
    type: NAME,
    payload: link
});

export const showB2T = (link) => ({
    type: BACK_TO_TOP,
    payload: link
});

export const setEmail = (link) => ({
    type: EMAIL,
    payload: link
});

export const setNumber = (link) => ({
    type: NUMBER,
    payload: link
});

export const setStreet = (link) => ({
    type: STREET,
    payload: link
});

export const setDelivery = (link) => ({
    type: DELIVERY,
    payload: link
});

export const setState = (link) => ({
    type: STATE,
    payload: link
});

export const setDescription = (link) => ({
    type: DESC,
    payload: link
});