import React from 'react';
import './features.min.css';

import Section from '../Style/Style';
// import stan from '../../assets/images/stans.jpg';
import stan from '../../assets/images/projects/tv.jpg';
import stan_painted from '../../assets/images/stans_painted.jpg';
import monkey_bars from '../../assets/images/monkey_bars.jpg';

const Features = (props) => {
    // const _handleClick = (e) => {
    //     e.preventDefault();
    //     props.CallFancy(e.currentTarget.href, e.currentTarget.dataset['caption']);
    // }
  return (
        <div className="section white-bg" id="services">
            <div className="container">
                <div className="text-center" style={{marginBottom: '30px'}}>
                    <h3 className={`title ${props.font ? "disl-font" : ""}`}>Services</h3>
                    <p className={props.font ? "disl-font" : ""}>Keeping you informed on progress of a job</p>
                </div>
                <div className="row align-items-stretch justify-content-center">
                    <div className="col-12 col-lg-4 mb-2 text-center d-flex">
                        <div className="panel bg-light-blue shadow">
                            <img src={stan} alt={stan} className="img-fluid" />
                            <div className="information">
                                <h3 className={`title ${props.font ? "disl-font" : ""}`}>STYLE</h3>
                                <p className={props.font ? "disl-font" : ""}>I make a range of products with style suited for the home/business</p>
                            </div>
                       </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-2 text-center d-flex">
                        <div className="panel bg-light-blue2 shadow">
                            <img src={stan_painted} alt={stan_painted} className="img-fluid" />
                            <div className="information">
                                <h3 className={`title ${props.font ? "disl-font" : ""}`}>Working Stands</h3>
                                <p className={props.font ? "disl-font" : ""}>Offering working stands for all your working needs with ease bringing your work to a comfortable height to make your work easier.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-2 text-center d-flex">
                        <div className="panel bg-light-blue shadow">
                            <img src={monkey_bars} alt={monkey_bars} className="img-fluid" />
                            <div className="information">
                                <h3 className={`title ${props.font ? "disl-font" : ""}`}>Product Range</h3>
                                <p className={props.font ? "disl-font" : ""}>I build a range of products suited to children's play equipment to factory ready work benches.</p>
                            </div>
                       </div>
                   </div>
               </div>
           </div>
           <Section color="var(--gray-bg)"/> 
        </div>
  );
}

export default Features;