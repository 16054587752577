import React from "react";
import { Link } from "react-router-dom";
import Features from "../../Components/Features/Features";
import Intro from "../../Components/Intro/Intro";
import Posts from "../../Components/Posts/Posts";
import VideoPlayer from "../../Components/VideoPlayer/VideoPlayer";
import Section from "../../HOC/Section";
import "./home.min.css";

import thumb from "../../assets/images/thumb.png";
import video from "../../assets/video/promo.mp4";

const Home = (props) => {
  props.s2t();
  return (
    <div>
      <Intro font={props.font} />
      <Section color="grey" id="promo" size="lg">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 mb-sm-3">
            <h1 style={{ fontSize: "40px", lineHeight: "1.1" }}>
              HEFF'S <br />
              <span style={{ fontWeight: 800 }} className="second-text-color">
                FABRICATIONS
              </span>
            </h1>
            <p>
              Welcome to HeffsFabrications, your premier destination for
              top-notch welding and fabrication services in Riverton, South
              Australia. At HeffsFabrications, we take pride in our craft,
              channeling our expertise as seasoned welders to bring your ideas
              to life. Our commitment extends beyond mere production – we sculpt
              dreams into reality. Our mantra is simple:{" "}
              <strong>
                <u>unmatched quality meets unbeatable affordability</u>
              </strong>
              . With a focus on using only the finest materials, we ensure that
              each project we undertake not only meets but exceeds your
              expectations. From custom metalwork to intricate welding,
              HeffsFabrications is your trusted partner. Explore a world where
              affordability and excellence unite
              <br />
              <br />
              experience the artistry of HeffsFabrications today.
            </p>
            <Link
              to="/quote"
              className="main-btn secondary-btn-outline rounded-5 btn-hover px-5 py-2 mt-3 mt-md-4"
            >
              Request Quote
            </Link>
          </div>
          <div className="col-12 col-lg-6 mb-sm-3">
            <VideoPlayer
              src={video}
              thumb={thumb}
              title="Heff's Fabrications Promo"
            />
          </div>
        </div>
      </Section>
      <Features
        onSetImage={props.onSetImage}
        onSetImageCap={props.onSetImageCap}
        CallFancy={props.CallFancy}
        font={props.font}
      />
      <Posts />
    </div>
  );
};

export default Home;
