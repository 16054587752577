import React from 'react';
import Section from '../../../Components/Style/Style';

import Me from '../../../assets/images/projects/me.jpg';

const Section1 = (props) => {
    const _handleClick = (e) => {
        e.preventDefault();
        props.CallFancy(e.currentTarget.href, e.currentTarget.dataset['caption']);
    }
  return (
        <div className="section">
            <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-4 offset-md-2 mb-3">
                        <div className="border-image img-border-invert">
                            <a aria-label='Stands' data-caption="Me doing my thing" href={Me} onClick={_handleClick}>
                                <img src={Me} alt={Me} className="img-fluid"/>
                            </a>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 ps-md-5">
                        <div className="text-right">
                            <h2 className={`${props.font ? "disl-font ":""} main-text-color font-35 mb-0`}>About</h2>
                            <h2 className={`${props.font ? "disl-font ":""} second-text-color font-35 text-bold`}>Shawn 
                                <span className={`ms-2${props.font ? "disl-font ":""} main-text-color`}> Heffernan</span>
                            </h2>
                        </div>
                        <p className={props.font ? "disl-font pb-2 text-left" : "pb-2 text-left"}>Hi, I'm Shawn Heffernan, I'm the sole founder and fabricator of Heff's Fabrications. <br />
                        I make a variety of projects that range from Workbenches to mailboxes. I take request on projects to bring you the product you need to keep your job on the go.<br /><br/>
                        My workshop is fitted out with new equiptment that is ideal for small fabrications, repairs and getting your job done in a timely manner.<br/>
                        I also deliver the completed work to your location for a small fee and also offer small range steel sales at an affordable price. 
                        </p>
                    </div>
                </div>
            </div>
            <Section color="#282828"/>
        </div>
  );
}

export default Section1;