import React from 'react';
import './404.min.css';

const _404 = (props) => {
  return (
        <div className="_404 overlay-6">
            <div className="z-index">
                <h1 className="mb-1"><span className="second-text-color"></span>404</h1>
                <h2 className='mt-0'>Page not found</h2>
            </div>
           
        </div>
  );
}

export default _404;