import {
   NAVBAR, BACK_TO_TOP,
   NAME, EMAIL, NUMBER, STREET, DELIVERY, STATE, EMAILED, DESC, NOTIFY } from './constants';

const initialStateFB = {
   navbar: undefined,
   b2t: undefined
}

export const BaseReducer = (state=initialStateFB, action={}) => { 
   switch(action.type) {
      case NAVBAR:
         return Object.assign({}, state, {navbar: action.payload});
      case BACK_TO_TOP:
         return Object.assign({}, state, {b2t: action.payload});
      default:
         return state;
   }
}

const initialStateMail = {
   name: '',
   email: '',
   number: '',
   street: '',
   delivery: false,
   state: 'South Australia',
   emailed: false,
   description: '',
   notify: false
}

export const emailReducer = (state=initialStateMail, action={}) => {
   switch(action.type) {
      case NOTIFY:
         return Object.assign({}, state, {notify: action.payload});
      case NAME:
         return Object.assign({}, state, {name: action.payload});
      case EMAIL:
         return Object.assign({}, state, {email: action.payload});
      case NUMBER:
         return Object.assign({}, state, {number: action.payload});
      case STREET:
         return Object.assign({}, state, {street: action.payload});
      case DELIVERY:
         return Object.assign({}, state, {delivery: action.payload});
      case STATE:
         return Object.assign({}, state, {state: action.payload});
      case EMAILED:
         return Object.assign({}, state, {emailed: action.payload});
      case DESC:
         return Object.assign({}, state, {description: action.payload});
      default:
         return state;
   }
}