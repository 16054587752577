import React from 'react';
import './banner.min.css';

const Banner = (props) => {
  return (
        <div className="banner">
           <span>Copyright © 2021 <a href="https://heffsfabrications.com.au">Heff's Fabrications</a>, All rights reserved.</span>
        </div>
  );
}

export default Banner;