import React,{useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import InputGroup from 'react-bootstrap/InputGroup';

const QuoteForm = (props) => {
    const [namePassed, setNamePassed] = useState(true);
    const [emailPassed, setEmailPassed] = useState(true);
    const submit = (e) => {
        e.preventDefault();
        // 0 = name
        // 1 = email
        // 2 = number
        // 3 = street
        // 4 = town
        // 5 = state
        // 6 = delivery (checked)
        if(props.name.length >= 5) {
            if(props.email.includes('@') && props.email.length >= 5) {
                sendMail(props.name,props.email,props.number,props.street,props.state,props.delivery?"Delivery Included":"Pickup Required",props.description);
                if(!props.emailed) {
                    props.onSetEmailed(true);
                    props.onSetNotify(true)
                    setTimeout( () => {
                        props.onSetEmailed(false);
                        props.onSetNotify(false)
                        cleanup();
                    }, 5000);
                }
            } else {
              setEmailPassed(false)
              setTimeout( () => {
                setEmailPassed(true)
              }, 3000);
            }
        } else {
            setNamePassed(false)
            setTimeout( () => {
                setNamePassed(true)
            }, 3000);
        }
    }
    const cleanup = () => {
        setEmailPassed(true);
        setNamePassed(true);
        let name = document.getElementById('name');
        let email = document.getElementById('email');
        let number = document.getElementById('number');
        let street = document.getElementById('street');
        let state = document.getElementById('state');
        let check = document.getElementById('checkbox');
        let desc = document.getElementById('description');
        name.value = '';
        email.value = '';
        number.value = '';
        street.value = '';
        state.value = 'South Australia';
        desc.value = '';
        check.checked = false;
        props.onSetDelivery(false);
        props.onSetName('');
        props.onSetEmail('');
        props.onSetNumber('');
        props.onSetStreet('');
        props.onSetState('');
        props.onSetDescription('');
      }
    
      
      const sendMail = (name, email, number, street, state, delivery, description) => {
          const url = process.env.PUBLIC_URL + "/assets/mail/mail.php"
    
          axios.post(url,JSON.stringify({
              name: name,
              email: email,
              number: number,
              street: street,
              state: state,
              delivery: delivery,
              description: description,
              submit: 'send mail',
              headers: {'Content-Type': 'application/json'}
          })).then(function(response) {
              console.log(response.data);
          })
          .catch(function (error) {
              if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
              console.log(error.config);
          });
      }
    return (        
        <Form onSubmit={submit}>
            <Row className="mb-2">
                <Form.Group as={Col} className="col-12 col-md-6 mb-3 input-style-2">
                    <Form.Label>First Last Name</Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><i className='lni lni-user'></i></InputGroup.Text>
                        <Form.Control type="text" id="name" placeholder="Enter Name" onChange={(e)=>props.onSetName(e.target.value)} />
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} className="col-12 col-md-6 mb-3 input-style-2">
                    <Form.Label>Email</Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><i className='lni lni-envelope'></i></InputGroup.Text>
                        <Form.Control type="email" id="email" placeholder="Enter email" onChange={(e)=>props.onSetEmail(e.target.value)} />
                    </InputGroup>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group as={Col} className="col-12 col-md-3 mb-3 input-style-2">
                    <Form.Label>Phone Number</Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><i className='lni lni-mobile'></i></InputGroup.Text>
                        <Form.Control type="number" id="number" placeholder="0412345678" onChange={(e)=>props.onSetNumber(e.target.value)}/>
                    </InputGroup>
                </Form.Group>
                <Form.Group as={Col} className="col-12 col-md-6 mb-3 input-style-2">
                    <Form.Label>Address</Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><i className='lni lni-map-marker'></i></InputGroup.Text>
                        <Form.Control id="street" placeholder="1234 Main St, Riverton" onChange={(e)=>props.onSetStreet(e.target.value)} />
                    </InputGroup>
                </Form.Group>                
                <Form.Group as={Col} className="col-12 col-md-3 mb-3 input-style-2">
                    <Form.Label>State</Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><i className='lni lni-travel'></i></InputGroup.Text>
                        <Form.Select id="state" defaultValue="Choose..." onChange={(e)=>props.onSetState(e.target.value)}>
                            <option value='South Australia'>South Australia</option>
                            <option value='OTHER'>Other State</option>
                        </Form.Select>
                    </InputGroup>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className="col-12 mb-3 input-style-2">
                    <Form.Label>Description</Form.Label>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1"><i className='lni lni-notepad'></i></InputGroup.Text>
                        <Form.Control as="textarea" rows={5} id="description" placeholder={`Hi Shawn,\nCan i get a quote on ...`} onChange={(e)=>props.onSetDescription(e.target.value)} />
                    </InputGroup>
                </Form.Group>
            </Row>
            <Row>
                <Form.Group className="d-flex align-items-center justify-content-end mt-3 mt-md-4 mb-3 input-style-2">
                    {!namePassed || !emailPassed?<p className='me-auto' style={{color: '#ff0000'}}><b>ERROR:</b> Name and Email must be filled</p>:null}
                    {props.emailed?<p className='me-auto' style={{color: '#33cc00', fontWeight: 700}}>Email Sent Successful</p>:null}
                    <Form.Check type="checkbox" label="Include Delivery" className='input-style-2-checkbox me-3' id="checkbox" onChange={(e)=>props.onSetDelivery(e.target.checked)}/>
                    <Button type="submit" className="main-btn secondary-btn-outline rounded-5 btn-hover px-5 py-2">
                        {props.emailed?<span className='d-flex align-items-center'><Spinner style={{width: '20px', height: '20px'}} animation="border" variant="info" className="me-2" />Loading...</span>:<span>Submit</span>}
                    </Button>
                </Form.Group>
            </Row>
        </Form>
    );
}

export default QuoteForm;