export const NAVBAR = 'NAVBAR';
export const BACK_TO_TOP = 'BACK_TO_TOP';
export const EMAILED = 'EMAILED';
export const NAME = 'NAME';
export const EMAIL = 'EMAIL';
export const NUMBER = 'NUMBER';
export const STREET = 'STREET';
export const DELIVERY = 'DELIVERY';
export const STATE = 'STATE';
export const DESC = 'DESC';
export const NOTIFY = 'NOTIFY';
