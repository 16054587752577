import React from 'react';
import bench from '../../../assets/images/projects/bench.jpg';
// import '../specials.css';

const Specials = (props) => {
    const _handleClick = (e) => {
        e.preventDefault();
        props.CallFancy(e.currentTarget.href, e.currentTarget.dataset['caption']);
    }
  return (
        <div className="z-index specials" style={{overflow: 'hidden'}}>
            <div className="container grid-xl" style={{marginBottom: '60px'}}>
                <h1 className={`${props.font?"disl-font":""} mb-1`}>This <span className="second-text-color">months</span></h1>
                <h1 className={`${props.font?"disl-font":""} mt-0 mb-3`}>Special</h1>
            
                <div className="columns align-items-center justify-content-center">
                    <div className="column col-md-12 col-6 text-center mb-3">
                        <h3 className={`${props.font?"disl-font":""} `}>Get a quote now</h3>
                        <button className={`${props.font?"disl-font":""} btn btn-specials`} onClick={() => props.onShowQuoteModel('active')}>Request A Quote</button>
                    </div>
                    <div className="column col-md-12 col-6">
                        <div className="border-image img-border-invert">
                            <a aria-label='Stands' data-caption="Workbench" href={bench} onClick={_handleClick}>
                                <img src={bench} alt={bench} className="img-responsive"/>
                            </a>
                        </div>            
                    </div>
                </div>
            </div>
           
        </div>
  );
}

export default Specials;