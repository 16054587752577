import React from 'react';
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Banner from '../Components/Banner/Banner';
import B2T from '../Components/B2T/B2T';

const Layout = (props) => {
  return (
        <div>
          <B2T b2t={props.b2t}/>
          <Header shrink={props.shrink} toggle={props.toggle} toggler={props.toggler} font={props.font} onSetDyslexia={props.onSetDyslexia}/>
          {props.children}
          <Footer font={props.font}/>
          <Banner />
        </div>
  );
}

export default Layout;