import React from 'react';
import './footer.min.css';
import {Link} from 'react-router-dom';

import Logo from '../../assets/images/Logo.png';
import * as Scroll from "react-scroll";
const Links = Scroll.Link;
const Footer = (props) => {
  return (
        <div className="section footer">
          <div className="container">
            <div className="socials align-items-center">
              <span className={`mr-auto ${props.font ? "disl-font" : ""}`}>
                Keep up to date with my socials:
              </span>
              <span>
                <a href="https://www.linkedin.com/in/shawn-heffernan-ab2590181/" className="fa-icon me-3" target="_blank" rel="noreferrer">
                  <i style={{fontWeight: 500, fontSize: '1.5rem'}} className="lni lni-linkedin"></i>
                </a>
                <a href="https://instagram.com/pippy_and_cherry/" className="fa-icon me-3" target="_blank" rel="noreferrer">
                  <i style={{fontWeight: 500, fontSize: '1.5rem'}} className="lni lni-instagram"></i>
                </a>
                <a href="https://www.facebook.com/profile.php?id=100069782825462" target="_blank" rel="noreferrer" className="fa-icon">
                  <i style={{fontWeight: 500, fontSize: '1.5rem'}} className="lni lni-facebook"></i>
                </a>
              </span>
            </div>
            <div className="row align-items-center">
              <div className="column col-12 col-md-4 mb-2">
                <img src={Logo} alt={Logo} height={50} />
                <div className={`mt-2 ${props.font ? "disl-font" : ""}`}>
                  HeffsFabrications is home owned business that is dedicated to producing the best product as described to your specification in a timely manner.
                </div>
              </div>
              <div className="col-12 col-md-4 mb-2">
                <div className="footer-nav">
                {document.location.href.includes('about') || document.location.href.includes('specials') || document.location.href.includes('quote')?<>
                  <Link to="/" onClick={props.toggler}>Home</Link></>:<>
                  <Links to="home" spy={true} smooth={true} offset={0} duration={500}>Home</Links>
                  <Links to="promo"spy={true} smooth={true} offset={0} duration={500}>Promo</Links>                                  
                  <Links to="services" spy={true} smooth={true} offset={0} duration={500}>Services</Links>
                  <Links to="projects" spy={true} smooth={true} offset={0} duration={500}>Projects</Links></>}
                {!document.location.href.includes('quote')?
                  <Link to="/quote" className='nav-link'>Quote</Link>:null}
                
                {!document.location.href.includes('about')?
                  <Link to="/about" onClick={props.toggler}>About</Link>:null}
                </div>
              </div>
              <div className="col-12 col-md-4 mb-2">
                <h5 className={props.font ? "disl-font" : ""}>Contact</h5>
                <div className="contacts">
                  <div className="item">
                    <span className={`d-flex align-items-center ${props.font ? "disl-font":'' }`}><i style={{fontWeight: 700, fontSize: '1.5rem'}} className="lni lni-map me-3"></i> 18 Frederick Place, Riverton SA 5412</span>
                  </div>
                  <div className="item">
                    <span className={`d-flex align-items-center ${props.font ? "disl-font":'' }`}><i style={{fontWeight: 700, fontSize: '1.5rem'}} className="lni lni-envelope me-3"></i> sheffernan@heffsfabrications.com.au</span>
                  </div>
                  <div className="item">
                    <span className={`d-flex align-items-center ${props.font ? "disl-font":'' }`}><i style={{fontWeight: 700, fontSize: '1.5rem'}} className="lni lni-mobile me-3"></i> 0408 783 268</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
           
        </div>
  );
}

export default Footer;